import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Presales from './pages/Presales';
import GoogleCallback from './pages/Auth';
import { useEffect, useState } from 'react';
import useAuth from "./useAuth";
import Login from "./pages/Login";

const NotFound = ({})=>{
  return <div className="w-full flex justify-center items-center bg-gray-50">
    <h1 className='text-gray-900 text-4xl font-bold'>
      404
    </h1>
  </div>
}

function App() {

  const [userInfo, checkAuth] = useAuth();
  const [loading, setLoading] = useState(true);
  const path = window.location.pathname;

  useEffect(()=>{
    if(!userInfo){
      checkAuth(r=>setLoading(false));
    } else{
      setLoading(false);
      
      
    }
  }, [userInfo])

  return (
    <BrowserRouter>
      <Routes>
      {
              !userInfo&&<Route path="/auth">
              
              <Route path="google-callback" element={<GoogleCallback/>}/>
            </Route>
            }
            {
          !userInfo  ?


            <>
            {
               !loading&&<><Route path="*" >
               <Route path="*" element={<Navigate to={`/login?rd=${path}`} />} />
             </Route>
             <Route path="">
               <Route path="*" element={<Navigate to={`/login?rd=${path}`} />} />
               <Route index={true} path="login" element={<Login />} />
               <Route path="" element={<Navigate to={`/login?rd=${path}`} />} />
             </Route></>
            }
            
              
            </>
            :<>
            <Route path="*" element={<NotFound/>}/>
            <Route path="presales/:leadId/contacts" element={<Presales/>}/>
            </>
            }
      </Routes>
    </BrowserRouter>
  );
}

export default App;
