import axiosClient from "./axiosClient";



let user;

const setUser = (u)=>user=u;


const useAuth = () =>{

  const checkAuth = (callback) => {
    axiosClient({
        url:"auth/profile",
        method:"get"
    }).then(response=>{
        let data = response.data;
        switch(data.claims){
            case "employee":
                data["level"]=1;
                    break;
                    case "admin":
                data["level"]=2;
                    break;
                    default:
                    data["level"]=0;
                    break;
                    
        }
        if(data.claims!="customer"){
            setUser(response.data);
            callback(response.data);
        }
        else{
            setUser(null);
            callback(null);
        }
        
    }).catch(()=>{
        setUser(null);
        callback(null);
    })
               
  }


  return [user, checkAuth];
}

export default useAuth;