import { sha512 } from 'js-sha512';
import { base64 } from 'base-64';
import { useEffect, useState } from 'react';
import axiosClient from '../axiosClient';
import JSEncrypt from 'jsencrypt';



const useEncryptor = () =>  {
    const [publicKey, setPublicKey] = useState();
    

    

    
    const getPublicKey = async () => {
        if(publicKey){
            return publicKey;
        }
        
        let response =await axiosClient({
            method: "GET",
            url: `/auth/rsa`
        });
        let key = response.data;
        setPublicKey(key);
        return key;


    }

    
    

    const EncryptMessage = async (message) => {
        let key = publicKey;
        if(!key){
            key=await getPublicKey();
        }

        let encrypt = new JSEncrypt();
        encrypt.setPublicKey(key);
        let encrypted =encrypt.encrypt(message);

        return encrypted;
    }

    return [EncryptMessage];
}
const passwordHash = (password)=>{
    if(!password){
        return password;
    }

    var arrayBuffer = sha512.array(password);

    var base64 = base64.encode(arrayBuffer);

    return base64;
}

export {useEncryptor};

export default passwordHash;