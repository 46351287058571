import { Link } from "react-router-dom";

const PresalesWrapper = ({active, children})=>{
    const Element = ({children, to, disabled})=>{
        if(disabled){
            return <></>
        }
        return <li className="me-2">
            {
            
            children===active?<div className="inline-block cursor-pointer p-4 text-blue-500 border-b-2 border-blue-500 rounded-t-lg active" aria-current="page">{children}</div>:<Link  to={"../"+to} relative="path" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300">{children}</Link>
            }
        </li>
    }
    return <div className="w-full pr-4 font-helvetica">
    <nav className="text-sm font-medium text-center text-gray-500 border-b border-gray-300 w-full fixed bg-white z-50">
            <ul className="flex flex-wrap -mb-px">
               
                <Element to="contacts">Contacts</Element>
                <Element disabled to="notes">Notes</Element>
                <Element disabled to="calls">Calls</Element>
                <Element disabled to="bookings">Bookings</Element>
            </ul>
        </nav>

        {children}
        
    </div>
}
export default PresalesWrapper;