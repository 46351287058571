import { useParams, useSearchParams } from "react-router-dom";
import useData from "../hooks/useData.js";
import { useEffect, useRef, useState } from "react";
import PresalesWrapper from "./PresalesWrapper.js";
import axiosClient from "../axiosClient.js";
import OutsideAlerter from "../components/OutsideAlerter.js";
import { sendNotification } from "../components/Notification.js";
import LoadingAnimation from "../components/svg/LoadingAnimation.js";

const DisplayStatus = ({ status, loading }) => {
    const statusStyles = {
        Success: "bg-green-200 text-green-400",
        Invalid: "bg-red-200 text-red-400",
        "Not interested": "bg-red-200 text-red-400",
        Unprocessed: "bg-gray-200 text-gray-400",
        Unqualified: "bg-gray-200 text-gray-400",
        Redial: "bg-orange-200 text-orange-400"
    };

    return <div className={`${loading?"animate-pulse":""} px-2 py-1 rounded-full text-center ${statusStyles[status]}`}>{status}</div>;
};

const DisplayContactContact = (lead)=>{

    const DisplayPhone = ({name})=>{
        return <div className="space-y-2">
        <label className="text-xs">Phone number</label>
        <div className="p-3 pr-3 border-gray-300 border-2 rounded  flex justify-between items-center">
            <span>{lead?.[name]??"-"}</span>
            <div className={`${lead?.[name+"Success"]?"bg-green-300":"bg-gray-300"} w-2 h-2 rounded-full`}></div>
        </div>
    </div>
    }

    return <>
    

            <div className=" grid w-full grid-cols-2 gap-4 text-gray-400 text-sm pb-8">
                <DisplayPhone name="phone"/>
                <DisplayPhone name="phoneOffice"/>
                <DisplayPhone name="phoneDirect"/>
                <DisplayPhone name="phoneHQ"/>
            </div></>
}
const DisplayContactNotes = (lead)=>{
    let notes = (lead?.notes?.map(note=>{
        
        return {
            ts: new Date(note.timestamp),
            note: note.note,
            user:note.user
        };
    })??[]).toSorted((n1,n2)=>n2.ts-n1.ts);

    return <div style={{marginTop:"1.5rem"}} className="  text-sm  overflow-auto no-scrollbar ">
       
        <div  className="space-y-8 max-h-64 no-scrollbar">
        {
            
            notes.length>0?notes.map((note,index)=><div key={index} className=" last:pb-6">
                <div className="p-3 pr-6 bg-gray-200 text-gray-600 rounded  flex justify-between items-center">{note.note}</div>
                <div className="flex space-x-6 justify-between text-gray-400 mt-2 text-xs">
                    <span>{note.user??"Unknown user"}</span>
                    <span className="text-right">{(note.ts ?.toLocaleDateString("da-DK",{
                                                                                          month:"short",
                                                                                          year:"numeric",
                                                                                          day:"2-digit",
                                                                                          hour: "2-digit",
                                                                                          minute:"2-digit"
                                                                                                                          
                                                                                      }))}</span>
                </div>

            </div>):<div className="p-3 pr-6 mb-6 bg-gray-200 text-gray-600 rounded  flex justify-between items-center">No notes</div>
        }
        
        </div>
        
    </div>;
}

const DisplayContact = ({state, tabState}) =>{
    const [lead, setLead] = state;
    const [display, setDisplay] = useState("hidden");
    const [tab, setTab] = tabState;

    useEffect(()=>{
        if(lead){
            setDisplay("flex")
        } else{
            setDisplay("hidden")
        }
    },[lead])

    
    const cancel = ()=>{
        setLead(null);
    }

    
    const Element = ({children, to})=>{
        return <li className="me-2">
            {
            
            to===tab?<div className="inline-block cursor-pointer p-4 text-blue-500 border-b-2 border-blue-500 rounded-t-lg active " aria-current="page">{children}</div>:<button  onClick={e=>setTab(to)} relative="path" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300">{children}</button>
            }
        </li>
    }
    
    return <div className={`fixed bg-gray-500 bg-opacity-25 z-50 w-screen font-helvetica h-screen  ${display}  items-center justify-center`} >
        <button onClick={cancel} className="absolute cursor-default z-40 w-full h-full"></button>
        <div className="z-50 bg-white rounded shadow-md p-3 mt-16 max-w-md w-full">
        <div className="p-4">
            <nav className="text-sm font-medium text-center text-gray-500 border-b border-gray-300 mb-12">
                <ul className="flex flex-wrap -mb-px">
                    <Element to="contact">Contact</Element>
                    <Element to="notes">Notes</Element>
                </ul>
            </nav>
            <div className="mb-8">
                <div className=" text-md text-gray-600 font-bold">
                    {lead?.name}
                </div>
                <div className=" text-sm text-gray-400">
                    {lead?.jobTitle}
                </div>
            </div>
            {
                display==="flex"&&<>
                {
                    tab=="contact"&&DisplayContactContact(lead)
                }
                {
                    tab=="notes"&&DisplayContactNotes(lead)
                }
                </>
            }

            <div className="pt-7 border-t-[1px] border-gray-300 flex justify-end space-x-3">
                <button onClick={cancel} className="bg-gray-300 text-gray-600 rounded-3xl flex py-1.5 px-4 items-center text-sm">
                    Cancel
                </button>
                <a href={`https://app.adversus.io/reactive-dialer?campaign=${lead?.campaignId}&lead=${lead?.Id}`} target="_blank"  className="bg-blue-500 text-white rounded-3xl flex py-1.5 px-4 items-center text-sm">
                    View contact
                </a>
                

            </div>

    </div>
            
        </div>
    </div>
}

const FixedCheckbox = ({onChange, value})=>{
    const [currentValue, setCurrentValue] = useState(false);
    
    useEffect(()=>{
        setCurrentValue(value);
    },[value])
    const toggleValue = ()=>{
        let newValue = !currentValue;
        setCurrentValue(newValue);
        if(onChange){
            onChange(newValue);
        }
    }

    return <input checked={currentValue}  onChange={toggleValue} type="checkbox" className="block border-gray-300 w-5 h-5 rounded border-2 text-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"/>
}

const Presales = () => {
    const {leadId} = useParams();
    const [sort, setSort] = useState({column:null, direction:1});
    const [search, setSearch] = useState(null);
    const [sortedLeads, setSortedLeads] = useState([]);
    const [similarLeads, setSimilarLeads] = useState([]);
    const [error, setError] = useState(null);
    const [allChecked, setAllChecked] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [displayContact, setDisplayContact] = useState(null);
    const [tab, setTab] = useState("contact");
    const [thisLead, setThisLead] = useState({});
    const [loading, setLoading] = useState(true);

    const openContactModal = (lead, tab)=>{
        setDisplayContact(lead);
        setTab(tab);
    }

    const enqueue = (action) =>{
        var leads = similarLeads.filter(lead=>lead._selected).map(lead=>lead.Id).join(',');

        setShowOptions(false);

        var newLeads = similarLeads.map(sl=>{
            if(sl._selected){
                sl._loading=action;
            }
            return sl;
        })

        setSimilarLeads(newLeads);

        axiosClient({
            url:`presales/enqueue?leads=${leads}&action=${action}`,
            method:"POST"
        }).then(()=>{

            



            setTimeout(()=>{

                newLeads=similarLeads.map(sl=>{
                    if(sl._loading==action){
                        sl._loading=null;
                        sl._selected=false;
                        switch(action){
                            case "Postpone":
                                sl.nextContactTime=Date.now()+172800000
                                break;
                            case "NextCall":
                                sl.nextContactTime=Date.now();
                                break;
                            case "CloseNoInterest":
                                sl.status="Not interested"
                                break;
                            case "CloseDuplicate":
                            case "CloseInDialogue":
                                sl.status="Invalid";    
                                break;
                        }

                    }
                    return sl;
                })
                sendNotification("success", "Successfully enqueued leads");
                setAllChecked(false);
                setSimilarLeads(newLeads);

            }, 1500);




        }).catch(setError);
    }


    const checkAll = ()=>{
        let newValue = !allChecked;

        let sl=similarLeads.map((lead)=>{
            lead._selected=newValue;
            return lead;
        })

        setSimilarLeads(sl);
        setAllChecked(newValue);
    }

    useEffect(()=>{
        if(leadId){
            axiosClient({
                url: "presales/similar?leadId="+leadId
            }).then((response)=>{
                let data = response.data.filter(lead=>{
                    if(lead.Id==leadId){
                        setThisLead(lead);
                        return false;
                    }
                    return true;
                });

                data=data.map((item,index)=>{

                    item._index=index;
        
                    return item;
                });

                setSimilarLeads(data);

                setLoading(false);

            }).catch(setError);
        }
    }, [])


 
    useEffect(()=>{
        let sl = similarLeads;
        if(search){
            let lowerSearch = search.toLowerCase();
            sl=sl.filter((l)=>{
                return l.name?.toLowerCase().includes(lowerSearch)||l.jobTitle?.toLowerCase().includes(lowerSearch);
            })
            
        }
        setSortedLeads(sl)
    },[search, similarLeads])
    useEffect(()=>{
        let sl = sortedLeads;

        if(!sort.column){
            setSortedLeads(sl);
        } else{
            let sorted =sl.toSorted((lead1, lead2)=>lead1[sort.column]>lead2[sort.column]?sort.direction:sort.direction*-1);
            setSortedLeads(sorted)
        }
    },[sort])




    const ColumnHeader = ({ children, value, className }) => {
        const handleClick = (e)=>{
            let newSort = {
                direction:sort.direction*-1,
                column:value
            };

            setSort(newSort);
            
        };

      
        let bottomClass = "before:border-b-[#ebecf5]";
        let topClass = "after:border-t-[#ebecf5]";

        if(value===sort.column){
            if(sort.direction===1){
                bottomClass="before:border-b-black";
            } else{
                topClass="after:border-t-black";
            }
        }

        return (
            <th className={`pr-6 text-left  ${className} `}>
                {
                    (value&&!loading)?<button className={`flex  w-full  
	cursor-pointer
	before:absolute before:right-2 before:leading-[0.5] before:content-[&quot;&quot;] before:w-0 before:h-0 before:border-x-[5px] before:border-x-transparent
	after:absolute after:right-2 after:leading-[0.5] after:content-[&quot;&quot;] after:w-0 after:h-0 after:border-x-[5px] after:border-x-transparent
	before:border-b-[5px] ${bottomClass} before:bottom-[52.5%]
	after:border-t-[5px] ${topClass} after:top-[52.5%]
 flex-grow relative`} onClick={handleClick}>
                    <span  >{children}</span>
                </button>:<span className={loading?"block w-fit pointer-events-none text-transparent select-none animate-pulse bg-gray-300 rounded-full":"text-gray-600"}>{children}</span>
                }
            </th>
        );
    };
    const CheckBox = ({index, value})=>{
        const handleChange = (value)=>{
            let newSimilar = similarLeads;
            newSimilar[index]._selected=value;
            setAllChecked(similarLeads.every(lead=>lead._selected));
        };
        return <FixedCheckbox value={similarLeads[index]?._selected} onChange={handleChange} />
    }

    return (
        
        <>
        <DisplayContact state={[displayContact, setDisplayContact]} tabState={[tab, setTab]}/>
        <PresalesWrapper active="Contacts">
            {
                (similarLeads.length==0&&!loading)?<div className="flex text-xl text-gray-700 font bold items-center justify-center h-full">
                No records found
                </div>:<><div className="flex justify-between mt-32" >
                {
                    loading?<div className="bg-gray-300 h-11 w-52 animate-pulse rounded-full"></div>:<div className="relative group max-w-md flex items-center h-10 border-gray-200 border-2  bg-gray-100 transition duration-75 focus-within:bg-white focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-inset focus-within:ring-blue-500 rounded" >
                        
                    <input   id="searchContacts" onChange={(e)=>setSearch(e.target.value)} placeholder="Search contacts" className="block w-full  h-full lg:text-lg  placeholder-gray-400 border-transparent  bg-transparent focus:ring-0 border-none outline-0 focus:placeholder-gray-400 " />
                    <label htmlFor="searchContacts"  className="cursor-text inset-y-0 left-0 flex items-center self-end justify-center w-10 h-full text-gray-500 group-focus-within:text-blue-500">
                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                        </svg>        </label>
                </div>
                }
    
                    {
                        loading?<div className="bg-gray-300 h-11 w-36 animate-pulse rounded-full"></div>:<div>
                        <div  className={`bg-blue-500 rounded-3xl origin-top-right  transition-all ${showOptions?"scale-125 text-white/0":"scale-100 text-white"}`}>
                            <button onClick={(e)=>setShowOptions(true)} className="flex py-3 px-6 space-x-2 items-center text-sm">
                            <span>Start journey</span>
                            <img className="h-5 w-5" src="/svg/o-chevron-down.svg" alt="View" />
                            </button>
                            
                        </div>
                        <OutsideAlerter onOutsideClick={(e)=>setShowOptions(false)} className={`bg-blue-500 w-52 text-white origin-top-right rounded-3xl absolute z-10 transition-transform  ${showOptions?"scale-100 -translate-x-12 -translate-y-12":"scale-0 -translate-x-16 -translate-y-10"}`}>
                            <button onClick={(e)=>setShowOptions(false)} className="w-full flex  flex-row-reverse py-3 px-6">
                            <img className="h-5 w-5 rotate-180" src="/svg/o-chevron-down.svg" alt="View" />
        
                            </button>
                            <div className="pt-3 pb-6 p-6 w-full" >
                            <ul className="space-y-4 pb-6 border-b-white/50 border-b-[1px] w-full mb-6">
                                <li>
                                    <button onClick={e=>enqueue("NextCall")} className="flex space-x-2 transition-opacity hover:opacity-75">
                                        <img className="w-5 h-5" src="/svg/s-fast-forward.svg" alt="View"/>
                                        <span>Set as next call</span>
                                    </button>
                                </li>
                                <li>
                                    <button onClick={e=>enqueue("Postpone")} className="flex space-x-2 transition-opacity hover:opacity-75">
                                        <img className="w-5 h-5" src="/svg/s-clock.svg" alt="View"/>
                                        <span>Postpone lead</span>
                                    </button>
                                    
                                </li>
                            </ul>
                            <ul className="space-y-4">
                                <li>
                                    <button onClick={e=>enqueue("CloseInDialogue")} className="transition-opacity hover:opacity-75">Close as "in dialogue"</button>
                                </li>
                                <li>
                                    <button onClick={e=>enqueue("CloseDuplicate")} className="transition-opacity hover:opacity-75">Close as "duplicate"</button>
                                </li>
                                <li>
                                    <button onClick={e=>enqueue("CloseNoInterest")} className="transition-opacity hover:opacity-75">Close as "no interest"</button>
                                </li>
                            </ul>
                            </div>
                            
                        </OutsideAlerter>
                        </div>
                    }
                </div>
    
    
                <div className="bg-white mt-12 font-semibold mb-24">
                    <table className="max-w-screen w-full ">
                        <thead className=" text-gray-800 border-200 border-b text-sm">
                            <tr>
                                <th className="pr-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-0">
                                    {
                                        loading?<div className="w-5 h-5 rounded-full bg-gray-300 animate-pulse"/>:<input checked={allChecked} onChange={checkAll} type="checkbox" className="block border-gray-300 w-5 h-5 rounded border-2 text-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"/>
                                    }
                                </th>
                                <ColumnHeader value="name">Name</ColumnHeader>
                                <ColumnHeader value="status" className=" w-32">Status</ColumnHeader>
                                <ColumnHeader value="nextContactTime" className=" w-32">Next call</ColumnHeader>
                                <ColumnHeader className=" w-0">Calls</ColumnHeader>
                                <ColumnHeader className=" w-0"></ColumnHeader>
                            </tr>
                        </thead>
                        <div className=" h-4"></div>
                        <tbody className="bg-white text-gray-400 mt-16">
                            {sortedLeads.map((lead, index) => (
                                <tr className={` cursor-pointer space-x-6 hover:bg-gray-50 ${lead._loading?"animate-pulse bg-gray-100":""}`}  key={index}>
                                    <td className="pr-6 py-2 whitespace-nowrap">
                                    {
                                        lead._loading?<LoadingAnimation className="w-5 h-5 "/>:<CheckBox value={lead?._selected} index={lead._index} />
                                    }
                                    </td>
                                    <td  className="py-2  text-sm pr-6">
                                        <button
                                            onClick={e=>openContactModal(lead, "contact")}
                                            className="text-blue-500  text-left hover:underline line-clamp-1"
                                        >
                                            {lead.name ?? "Ukend navn"}
                                        </button>
                                        <div className=" font-normal text-xs line-clamp-2" title={lead.jobTitle}>{lead.jobTitle}</div>
                                    </td>
                                    {/* <td onClick={e=>setDisplayContact(lead)} className="py-2 whitespace-nowrap max-w-16 overflow-ellipsis overflow-hidden">{lead.jobTitle}</td> */}
                                    <td onClick={e=>openContactModal(lead, "contact")} className="py-2 pr-6 whitespace-nowrap text-xs font-normal">
                                        <DisplayStatus status={lead.status} />
                                    </td>
                                    <td onClick={e=>openContactModal(lead, "contact")} className="py-2 pr-6 whitespace-nowrap text-xs font-normal">
                                        {lead.nextContactTime? (new Date(lead.nextContactTime) ?.toLocaleDateString("da-DK",{
                                                                                              month:"short",
                                                                                              year:"numeric",
                                                                                              day:"2-digit",
                                                                                                                              
                                                                                          })) : "-"}
                                    </td>
                                    <td onClick={e=>openContactModal(lead, "notes")} className="py-2 pr-6 whitespace-nowrap text-blue-500 text-xs">{lead.callCount ?? 0}</td>
                                    <td className="py-2 whitespace-nowrap">
                                        <a href={`https://app.adversus.io/reactive-dialer?campaign=${lead?.campaignId}&lead=${lead?.Id}`} target="_top">
                                            <img className="h-5 w-5" src="/svg/o-external-link.svg" alt="View" />
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div></>
            }
            <div className=" flex items-center  justify-between fixed w-screen h-24 border-t-[1px] border-t-gray-300 bottom-0 bg-white pr-4">
                {
                    loading?<div className="bg-gray-300 h-6 animate-pulse w-32 rounded-full"/>:<span className="text-gray-800 font-semibold  text-lg">{thisLead?.business}</span>
                }
                

                {
                    loading?<div className="bg-gray-300 h-6 animate-pulse w-32 rounded-full"/>:<a href={"https://"+(thisLead?.website??`mindmill.dk/`).replace("https://", "")} target="_blank" className="text-gray-400 flex items-center space-x-2"><span>View website</span><img className="h-5 w-5 inline-block" src="/svg/o-external-link.svg" alt="View" /></a>
                }
            </div>
        </PresalesWrapper>
        </>
    );
};

export default Presales;
